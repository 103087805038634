import React, { lazy, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import ConnectPage from './pages/ConnectPage';
import PrivateRoute from './containers/PrivateRoute';
import { AppContextProvider } from './context/AppContext';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const Login = lazy(() => import('./pages/Login'))
const Layout = lazy(() => import('./containers/Layout'))

const queryClient = new QueryClient()

function App() {
  return (
    <>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <AccessibleNavigationAnnouncer />
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route exact path="/connect/:provider" component={ConnectPage} />

              <PrivateRoute component={Layout} />
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </AppContextProvider>
        </QueryClientProvider>
      </Router>
    </>
  )
}

export default App
