import axios from 'axios';
import auth from '../utils/auth';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers['Authorization'] = `Bearer ${auth.getToken()}`
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default axiosInstance;