/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import auth from '../../utils/auth';
import { postModelVerification, getUserModelProfile } from '../../api';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { model, user, isLoading } = useContext(AppContext);

  return (
      <Route
        {...rest}
        render={props => {
          if (isLoading)
            return;

          // Take model to application page if no model profile or model is waiting on approval.
          if (user !== null && location.pathname != '/signup') {
            if (!model || model.verificationStatus != 'approved')
              return <Redirect to="/signup" />
          }

          return user !== null ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: 'auth/login',
                state: { from: props.location },
              }}
            />
          )
        }} />
  );
}

export default PrivateRoute;
