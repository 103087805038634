import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getUserModelProfile } from '../api';
import ThemedSuspense from '../components/ThemedSuspense';
import { AppContext } from '../context/AppContext';

// Utils
import auth from '../utils/auth';
import request from '../utils/request';

class ConnectPage extends React.Component {
  static contextType = AppContext;

  componentDidMount() {
    const {
      match: { params: { provider } },
      location: { search },
    } = this.props;
    const requestURL = `${process.env.REACT_APP_API_URL}/auth/${provider}/callback${search}`;

    request(requestURL, { method: 'GET' })
      .then(async response =>  {
        auth.setToken(response.jwt, true);

        return this.context.refresh()
          .then(() => {
            this.redirectUser('/');
          });
      })
      .catch(err => {
        auth.clearAppStorage();
        console.log({err});
        console.log(err.response.payload);
        this.redirectUser('/auth/login');
      });
  }

  redirectUser = path => {
    this.props.history.push(path);
  };

  render() {
    return (
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <ThemedSuspense message="Connecting your account.  Please wait..." />
        </div>
      </div>
    );
  }
}

ConnectPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ConnectPage;
