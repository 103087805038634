import axios from './axios';
import qs from 'querystring';

export const getVideos = async (filter, locale) => {
    let query = qs.stringify(filter);

    if (query.length > 0) {
        query = '?' + query;
    }

    return axios.get(`/videos${query}`);
}

export const getVideoCategories = async (filter) => {
    let query = qs.stringify(filter);

    if (query.length > 0) {
        query = '?' + query;
    }
    return axios.get(`/video-categories/search${query}`);
}

export const getVideosCount = (filter) => {
    let query = qs.stringify(filter);
  
    return axios.get(`/videos/count?${query}`);
  }

export const getVideoBySlug = async (videoSlug) => {
    return axios.get(`/videos/slug/${videoSlug}`);
}

export const putVideo = async (videoId, video) => {
    return axios.put(`/videos/${videoId}`, video);
}

export const deleteVideo = async(videoId) => {
    return axios.delete(`/videos/${videoId}`);
}

export const postModelVerification = (formData) => {
    return axios.post(`/models/applications`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
}

export const putModelProfile = (modelId, formData) => {
    return axios.put(`/models/${modelId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

export const getUserModelProfile = () => {
    return axios.get('/models/me');
}

export const postNewVideo = (data) => {
    return axios.post('/videos', data);
}

export const getSignedVideoUploadUrl = (objectName, contentType) => {
    return axios.get('/videos/uploads/sign', {
        params: {
            objectName, contentType
        }
    })
}

export const getModelBanking = () => {
    return axios.get(`/models/me/banking`);
}

export const postModelBanking = (data) => {
    return axios.post(`/models/me/banking`, data);
}

export const getModelTax = () => {
    return axios.get(`/models/me/tax`);
}

export const postModelTax = (data) => {
    return axios.post(`/models/me/tax`, data);
}
export const getAvailableEarnings = () => {
    return axios.get(`/model-sales/me/available-earnings`);
}

export const getPendingEarnings = () => {
    return axios.get(`/model-sales/me/pending-earnings`);
}

export const getModelSales = (filter) => {
    let query = qs.stringify(filter);

    if (query.length > 0) {
        query = '?' + query;
    }

    return axios.get(`/model-sales/me${query}`);
}

export const getModelPayouts = () => {
    let query = qs.stringify({
        _sort: 'createdAt:DESC'
    });

    return axios.get(`/model-payouts/me?${query}`);
}

export const postModelPayout = (payoutRequest) => {
    return axios.post(`/model-payouts/me`, payoutRequest);
}

export const getUserProfile = () => {
    return axios.get('/users/me');
}

export const getCoPerformers = (filter) => {
    let query = qs.stringify(filter);

    if (query.length > 0) {
        query = '?' + query;
    }

    return axios.get(`/co-performers/me${query}`);
}

export const getCoPerformer = (id) => {
    return axios.get(`/co-performers/me/${id}`);
}

export const putCoPerformer = (id, data) => {
    return axios.put(`/co-performers/me/${id}`, data);
}

export const postCoPerformer = (data) => {
    return axios.post('/co-performers/me', data);
}

export const loginWithAuth0 = () => {
    return axios.get(`/auth/auth0/callback`)
}