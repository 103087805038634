import React, { useState, useEffect } from "react";
import { getUserProfile, getUserModelProfile } from '../api';
import auth from '../utils/auth';

export const AppContext = React.createContext();

export const AppContextProvider = props => {
    const [user, setUser] = useState(null);
    const [model, setModel] = useState(null);

    const [isLoading, setLoading] = useState(true);

    const refresh = async () => {
        try {
            const user = await getUserProfile();
            setUser(user.data);
            const model = await getUserModelProfile();
            setModel(model.data);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    
    const value = {
        user,
        setUser,
        model,
        setModel,
        isLoading,
        refresh
    }

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );
};